<template>
  <div>
    <b-modal
      id="task-add-task"
      ref="modal"
      title="Add a Task"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <p v-if="reference"><b>Reference: </b>{{ reference }}</p>

        <b-form-group
          label="Description"
          label-for="task-input"
          invalid-feedback="Description is required"
          :state="validateState('description')"
        >
          <b-form-textarea
            id="task-input"
            v-model="description"
            :state="validateState('description')"
            required
            placeholder=""
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          label="Due Date"
          label-for="due-date"
          invalid-feedback="Due Date is required"
          :state="validateState('due_date')"
        >
          <b-form-datepicker
            v-model="due_date"
            :min="min"
            locale="en"
          ></b-form-datepicker>
        </b-form-group>

      </form>
    </b-modal>
  </div>
</template>

<script>

import axios from "../../../shared/axios/auth-no-loader";
import { validationMixin } from 'vuelidate'
import {required} from "vuelidate/lib/validators";

const now = new Date()
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
const minDate = new Date(today)

export default {
  props: {
    task_type: {
      type: String,
      required: true
    },
    reference: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      error: false,
      due_date: null,
      description: "",
      taskState: null,
      min: minDate,
    };
  },
  mixin: [validationMixin],
  validations() {
      return {
        description: {required},
        due_date: {}
      }
  },
  methods: {
    validateState(name) {

      if (this.$v[name]) {
        const {$dirty, $error} = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    resetModal() {
      this.due_date = null;
      this.description = "";
      this.taskState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      this.$v.$touch();

      if(this.$v.$anyError) {

        this.error = true;

        return

      } else {

        let data;

        if (this.task_type === "claim") {
          data = {
            claim: this.$route.params.id,
            description: this.description,
            due_date: this.due_date
          };
        }
        if (this.task_type === "complaint") {
          data = {
            complaint: this.$route.params.id,
            description: this.description,
            due_date: this.due_date
          };
        }
        if (this.task_type === "general") {
          data = {
            description: this.description,
            due_date: this.due_date
          };
        }

        axios
          .post("/tasks/", data)
          .then(response => {
            response;
            this.due_date = "";
            this.description = "";

            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("task-add-task");
            });
          })
          .catch(error => {
            this.taskState = false;

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch("logout");
            }

            console.log(error.response);
          });
        }

      }
  }
};
</script>

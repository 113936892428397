<template>
  <div>
    <b-modal
      :ref="'content-modal-' + id" 
      :id="'content-modal-' + id"
      title="View File"
      size="xl"
      class="modal-max-height"
      centered
    >
      
      <iframe :src="content" class="w-100 vh-100" frameborder="0"></iframe>

    </b-modal>
  </div>
</template>

<script>
  

  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      content: {
        type: String,
        required: false,
      },
    },
  }
</script>

<style scoped>
.vh-100 {
    height: 70vh !important;
}
</style>
<template>

    <div>

    <b-tab v-if="loaded" title="Correspondence">

        <div v-if="response.data.length > 0">

            <p class="mb-3">
                Total: {{ response.pagination.total }}
                <b-button v-if="response.pagination.next_page_url" variant="outline-dark" size="sm" class="ml-2 float-right" @click="nextPage">Next Page</b-button>
                <b-button v-if="response.pagination.previous_page_url" variant="outline-dark" size="sm" class="ml-2 float-right" @click="previousPage">Previous Page</b-button>
            </p>

            <b-card class="card-child" header-bg-variant="" no-body v-for="(result, index) in response.data" :key="'key5_'+index">

                <template #header>
                    <p class=" m-0 p-0">{{ result.direction_pretty }} : {{ result.description }} <b-button @click="resendCorrespondence(result.id);" class="mt-2 mb-2 pull-right">Resend</b-button></p>
                </template>

                <table class="table">
                    <tr>
                        <th>
                            Method
                        </th>
                        <th>
                            Recipient
                        </th>
                        <th v-if="type == 'customer' || type == 'vet'">
                            Reference
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {{ result.contact_type_pretty }}
                        </td>
                        <td>
                            {{ result.recipient_pretty }} [{{ result.recipient_type.replace('_', ' ').ucwords() }}]
                        </td>
                        <td v-if="type == 'customer' || type == 'vet'">
                            {{ result.reference}}
                        </td>
                        <td>
                            {{ result.status_pretty }}
                        </td>
                        <td>
                            <b-button v-if="result.view_data" variant="light" v-b-toggle="'collapse-view-' + index" class="ml-2">View</b-button>
                            <b-button v-if="result.attachments.length > 0" variant="light" v-b-toggle="'collapse-attachment-' + index" class="ml-2">Attachments</b-button>
                            <b-button v-if="result.recordings.length > 0" variant="light" v-b-toggle="'collapse-recordings-' + index" class="ml-2">Recordings</b-button>
                            <b-button v-if="result.audits && result.audits.length > 0" variant="light" v-b-toggle="'collapse-audits-' + index" class="ml-2">Audit</b-button>
                        </td>
                    </tr>
                </table>

                <b-collapse v-if="result.audits && result.audits.length > 0" :id="'collapse-audits-' + index">
                    <div class=" bg-light">
                      <div class="col px-3">
                        
                        <table class="table" >
                            <tr>
                                <th>Status</th>
                                <th>Created</th>
                                <th></th>
                            </tr>
                            <tr v-for="(audit, index_) in result.audits" :key="'key6_'+index_">
                                <td>{{audit.sms_status}}</td>
                                <td>{{audit.created_at}}</td>
                            </tr>
                        </table>

                      </div>
                    </div>
                  </b-collapse>

                <b-collapse v-if="result.attachments && result.attachments.length > 0" :id="'collapse-attachment-' + index">
                    <div class=" bg-light">
                      <div class="col px-3">
                        
                        <table class="table" >
                            <tr>
                                <th>Name</th>
                                <th>Created</th>
                                <th></th>
                            </tr>
                            <tr v-for="(attachment, index_) in result.attachments" :key="'key7_'+index_">
                                <td>{{attachment.name}}</td>
                                <td>{{attachment.created}}</td>
                                <td>
                                    <b-button  variant="light" v-b-modal="'content-modal-' + index + '-attachment'" class="btn btn-sm btn-outline-dark ml-2">View</b-button>
                                </td>
                                
                                <filemodal v-bind:id="index + '-attachment'" v-bind:content="attachment.link"></filemodal>
                            </tr>
                        </table>

                      </div>
                    </div>
                  </b-collapse>

                <b-collapse v-if="result.recordings && result.recordings.length > 0" :id="'collapse-recordings-' + index">
                    <div class=" bg-light">
                      <div class="col px-3">
                        
                        <table class="table" >
                            <tr>
                                <th>Number</th>
                                <th>Recording</th>
                                <th></th>
                            </tr>
                            <tr v-for="(recording, index_) in result.recordings" :key="'key8_'+index_">
                                <td>{{recording.number}}</td>
                                <td>
                                <audio controls>
                                <source :src="recording.recording" type="audio/mpeg">
                                    Your browser does not support the audio element.
                                </audio>
                                </td>
                            </tr>
                        </table>

                      </div>
                    </div>
                  </b-collapse>

                <b-collapse v-if="result.view_data" :id="'collapse-view-' + index">
                    <div class=" bg-light">
                      <div class="col px-3">
                        
                        <div class="p-3" v-html="result.view_data"></div>

                      </div>
                    </div>
                  </b-collapse>

                

                <!--
                <b-card-text>
                    {{note.text}}
                </b-card-text>
                -->
                <template #footer>
                    <span class="float-left"><b>Created by:</b>&nbsp;<em>{{result.created_by_pretty}}</em></span>
                    <span class="float-right"><b>Created:</b>&nbsp;<em>{{result.created}}</em></span>
                </template>

            </b-card>

            <p>
                Total: {{ response.pagination.total }}
                <b-button v-if="response.pagination.next_page_url" variant="outline-dark" size="sm" class="ml-2 float-right" @click="nextPage">Next Page</b-button>
                <b-button v-if="response.pagination.previous_page_url" variant="outline-dark" size="sm" class="ml-2 float-right" @click="previousPage">Previous Page</b-button>
            </p>

        </div>

        <div v-else>
            <p class="text-center">No correspondence to date.</p>
        </div>

    </b-tab>

    </div>

</template>

<script>
import axios from '../../../../shared/axios/auth-no-loader'

import file_modal from '../modal/file'

export default {
    name: 'CorrespondenceTab',
    props: {
      type: {
        required: true,
      },
      object: {
        required: false,
      }
    },
    components: {
        filemodal: file_modal
    },
    data () {
        return {
            loaded: false,
            response: {}
        }
    },
    methods: {
        load () {

            axios.get('/correspondence/?type=' + this.type + '&object=' + this.object).then(
                response => {
                    this.response = response.data
                    this.loaded = true
                }
            )

        },
        resendCorrespondence(id) {

            axios.post('/correspondence/' + id + '/resend/').then(
                response => {
                    console.log(response)
                    //this.response = response.data
                    //this.loaded = true
                    this.load()
                    alert("resend success")
                }
            ).catch(error => {
                this.$store.state.loading = false
                // Unauthorised
                if (error.response.status === 401) {
                this.$store.dispatch('logout');
                }

                console.log(error.response)
            })

        },
        nextPage() {
            
            if (this.loaded) {
                axios.get(this.response.pagination.next_page_url).then(
                    response => {
                        this.response = response.data
                        this.loaded = true
                    }
                )
            }
        },
        previousPage() {
            
            if (this.loaded) {
                axios.get(this.response.pagination.previous_page_url).then(
                    response => {
                        this.response = response.data
                        this.loaded = true
                    }
                )
            }
        }
    },
    mounted() {
      this.load()
    }

}

</script>

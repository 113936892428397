<template>
  <div>
    <b-modal
      :ref="'correspondence-modal-' + status" 
      :id="'correspondence-modal-' + status"
      :title=modal_title
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <p v-if="reference"><b>Reference: </b>{{ reference }}</p>

        <b-form-group
          :label=field_label
          label-for="correspondence-input"
          invalid-feedback="Description is required"
          :state="correspondenceState"
        >
        <b-form-textarea
            id="correspondence-input"
            v-model="description"
            :state="correspondenceState"
            required
            placeholder=""
            rows=""
            max-rows="6"
        ></b-form-textarea>
        
        </b-form-group>

        <!--
        <b-form-group label="Contact via:" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="radio-group-2"
          v-model="contact_type"
          :aria-describedby="ariaDescribedby"
          name="radio-sub-component"
          required
          :state="correspondenceState"
        >
          <b-form-radio value="email">Email only</b-form-radio>
          <b-form-radio value="text">SMS only</b-form-radio>
          <b-form-radio value="both">Email & SMS</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      -->
      
      </form>
    </b-modal>
  </div>
</template>

<script>
  
  import axios from '../../../../shared/axios/auth-no-loader'

function titleCase(str) {
  return str.toLowerCase().split(' ').map(function(word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}
  export default {
    props: {
      correspondence_type: {
        type: String,
        required: true,
      },
      reference: {
        type: String,
        required: false,
      },
      status: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        description: '',
        contact_type: 'email',
        correspondenceState: null,
      }
    },
    computed: {
      modal_title () {

          if (this.status === 'free-format') {

            return 'Contact Customer'

          } else {
           
            let words = this.status.split('-')

            return titleCase(words.join(' '))
            
          }
      },
      field_label () {

          if (this.status === 'further-info-required') {
            return 'Reason'
          }
          if (this.status === 'refer-to-underwriter') {
            return 'Reason for referral'
          }
          if (this.status === 'free-format') {
            return 'What would you like to say?'
          }

      }
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.correspondenceState = valid
        return valid
      },
      resetModal() {
        this.description = ''
        this.correspondenceState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Push the name to submitted names

        let url
        let data

        if (this.correspondence_type === 'claim') {

          url = '/correspondence/claim/'

          if (this.status === 'refer-to-underwriter') {

            data = {
                claim: this.$route.params.id,
                letter_type: 8
            }

          }

          if (this.status === 'further-info-required') {

            data = {
                claim: this.$route.params.id,
                letter_type: 7,
                data: {
                  "free": this.description
                }
            }

          }

          if (this.status === 'free-format') {

            data = {
                claim: this.$route.params.id,
                letter_type: 13,
                contact_type: this.contact_type,
                data: {
                  "free": this.description
                }
            }

          }
          
          if (this.status === 'decline') {

            data = {
                claim: this.$route.params.id,
                letter_type: 13,
                data: {
                  "free": this.description
                }
            }

          }

        }

        if (this.correspondence_type === 'complaint') {

          url = '/correspondence/complaint/'
          data = {
              complaint: this.$route.params.id,
              description: this.description
          }

        }

        if (this.correspondence_type === 'general') {

          url = '/correspondence/'
          data = {
              description: this.description
          }

        }

        axios.post(url, data).then(
          response => {
            response
            this.description = ''

            //this.$v.$reset()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('correspondence-modal-' + this.status)
            })

          }
        ).catch(error => {

            this.correspondenceState = false
            this.correspondenceState = null
            alert('error')

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            

            console.log(error.response)
        })
        
      }
    }
  }
</script>